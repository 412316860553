import React from "react"
import { GlobalStyles } from "./src/theme"
import { handleTracking } from "./src/utils/handleTracking"
import "unfetch/polyfill"
// Required to enable image uploads with react gravity forms
import "base64-js"
import "./src/theme/index.css"

export const onInitialClientRender = () => {
  // Scroll to location.hash on page load
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    const hashElement = document.getElementById(window.location.hash.replace("#", ""))
    if (!!hashElement) {
      hashElement.scrollIntoView()
      window.scrollBy(0, -120)
    }
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  )
}
export const onRouteUpdate = ({ location }) => {
  process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && handleTracking("PageView", location)

  //   if (typeof window !== "undefined") {
  //     console.log("Injecting StackAdapt script...")

  //     // Ensure script is only added once
  //     if (!document.querySelector("script[src='https://tags.srv.stackadapt.com/events.js']")) {
  //       const script = document.createElement("script")
  //       script.src = "https://tags.srv.stackadapt.com/events.js"
  //       script.async = true

  //       script.onload = () => {
  //         console.log("StackAdapt pixel script loaded.")
  //         manuallyInitializeSaq()
  //       }

  //       document.head.appendChild(script)
  //     } else {
  //       manuallyInitializeSaq()
  //     }
  //   }
  // }

  // // 🔹 Manually initialize saq if undefined
  // const manuallyInitializeSaq = () => {
  //   if (typeof window.saq === "undefined") {
  //     console.log("Manually defining saq...")
  //     window.saq = function () {
  //       console.log("StackAdapt saq function called with:", arguments)
  //       ;(window.saq.queue = window.saq.queue || []).push(arguments)
  //     }
  //     window.saq.loaded = true
  //   }

  //   attachButtonEvent()
  // }

  // // 🔹 Attach event listener after `saq` is defined
  // const attachButtonEvent = () => {
  //   setTimeout(() => {
  //     const button = document.getElementById("whereToBuy")

  //     if (button) {
  //       console.log("Button found. Adding event listener.")
  //       button.addEventListener("click", () => {
  //         console.log("Button clicked! Triggering StackAdapt pixel.")
  //         if (typeof window.saq === "function") {
  //           window.saq("conv", "2W5dpN9sPXH0x1KalZ2uu5")
  //         } else {
  //           console.error("StackAdapt saq is still not available.")
  //         }
  //       })
  //     } else {
  //       console.warn("Button with ID 'whereToBuy' not found.")
  //     }
  //   }, 1000) // Delay ensures Gatsby has rendered the button
}
