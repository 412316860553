import React, { useState } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

// import material ui
import { Typography } from "@material-ui/core"

// import app components

import Link from "components/link"
import Edges from "components/edges"
import * as theme from "theme"
import { useStore } from "store"
import { getUrlPath } from "utils/getUrlPath"

const ProductNav = ({ item, isHoverActive, path }) => {
  const [activeProduct, setActiveProduct] = useState("Siding")
  const handleProductMenu = (e) => {
    setActiveProduct(e)
  }
  const [
    {
      appState: { activeSection }
    },
    dispatch
  ] = useStore()

  const isActive =
    !getUrlPath(path).includes("free-diy-project-plans") &&
    item?.childItems?.nodes?.some((navitem) =>
      navitem?.childItems?.nodes?.some((childItem) =>
        childItem?.childItems?.nodes?.some((childNavItem) =>
          getUrlPath(childNavItem?.url)?.includes(`/${activeSection}`)
        )
      )
    )

  return (
    <MenuItem key={item.id} component="div" isHoverActive={isHoverActive} isActive={isActive}>
      <Link to={getUrlPath(item.url)}>{item.label}</Link>

      {!!item.childItems && (
        <>
          <SubMenu>
            <div className="flex flex-col justify-center align-center relative bg-customegrey  w-max h-[320px]">
              {item.childItems.nodes.map((subItem, index) => {
                return (
                  <SubItem key={subItem.id} className={``}>
                    {isHoverActive && (
                      <span
                        className={` cursor-pointer px-[20px] py-[15px] w-[143px]  flex justify-center items-center text-[12px] uppercase ${
                          activeProduct === subItem.label ? "bg-lightgrey" : "bg-customegrey"
                        }`}
                        title={subItem.label}
                        onMouseOver={(e) => handleProductMenu(e.target.title)}
                        key={subItem?.id}
                      >
                        {Parser(subItem.label)}
                      </span>
                    )}
                    {activeProduct === subItem.label && (
                      <div
                        className={`absolute pt-[15px] left-[143px] bg-lightgrey top-0 gap-y-[10px] h-full grid  grid-cols-4 grid-rows-6 w-full min-w-[650px] `}
                      >
                        {subItem?.childItems.nodes.map((subSubItem, subindex) => {
                          return !subSubItem?.cssClasses?.includes("special-item") ? (
                            <div
                              className={`flex flex-col pt-[10px]  row-start-1 row-end-4  text-[14px] w-fit	${
                                subindex === 0 && "pl-[25px]"
                              }  ${subindex === 3 && "pr-[25px]"}`}
                            >
                              <span key={subSubItem?.id} className={`pb-[10px] text-[12px] uppercase`}>
                                {Parser(subSubItem.label)}
                              </span>
                              {subSubItem?.childItems.nodes.map((subSubSubItem) => {
                                return (
                                  <Link
                                    key={subSubSubItem?.id}
                                    to={subSubSubItem.url}
                                    onClick={() => {
                                      getUrlPath(item.url).replace(/\//g, "")
                                      // dispatch({
                                      //   type: "SET_ACTIVE_SECTION",
                                      //   payload: getUrlPath(item.url).replace(/\//g, "")
                                      // })
                                    }}
                                    className="font-light pb-[10px] text-[14px]"
                                  >
                                    {Parser(subSubSubItem.label)}
                                  </Link>
                                )
                              })}
                            </div>
                          ) : (
                            <div
                              key={subSubItem?.id}
                              className={`row-start-5 row-end-7 col-start-1 col-end-5  bg-superlightbg  text-[14px] grid  grid-cols-4 grid-rows-2 w-full  `}
                            >
                              <div className="pt-[10px] pl-[25px]  row-start-1 row-end-3 text-[12px] uppercase max-w-[140px]">
                                {Parser(subSubItem.label)}
                              </div>

                              <div className={`col-start-2 col-end-5 flex flex-wrap	w-full py-[10px] justify-start`}>
                                {subSubItem?.childItems.nodes.map((subSubSubItem) => {
                                  return (
                                    <Link
                                      key={subSubSubItem?.id}
                                      to={subSubSubItem.url}
                                      className={`flex  font-light text-[14px] items-center pb-[7px] ${
                                        subSubItem?.childItems?.nodes?.length > 4 ? "w-1/3 " : "w-1/2 max-w-[200px]"
                                      } `}
                                    >
                                      {Parser(subSubSubItem.label)}
                                    </Link>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </SubItem>
                )
              })}
            </div>
          </SubMenu>
        </>
      )}
    </MenuItem>
  )
}

const MenuItem = styled(({ isHoverActive, isActive, ...rest }) => <Typography {...rest} />)`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: ${theme.colors.primary};
  transition: 0.4s ease all;
  background: ${(props) => (props.isActive ? theme.colors.lightgrey : "transparent")};

  > a {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 12px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 25px;
      z-index: 1;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 102px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease all;
    display: block;
  }

  &:hover,
  &:focus {
    ${(props) =>
      props.isHoverActive &&
      `
      background: ${theme.colors.lightgrey};

      &:after {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        visibility: visible;
      }

      > div {
        transition: 0.2s ease all;
        transition-delay: 0.2s;
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }

      > a:after {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }
    `}
  }
`

const SubMenu = styled.div`
  position: absolute;
  z-index: 1200;
  top: 102px;
  width: fit-content;
  max-width: 1110px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.2s ease all;
  /* left: 0; */
  transform: translateX(-143px);
`

const SubItem = styled.div``
const SubSubItem = styled.div``

export default ProductNav
