import React from "react"
import styled from "styled-components"

// Import app components
import Button from "components/button"
import NavIcon from "../../assets/svg/navIcon.svg"
import * as theme from "theme"

import { handleTracking } from "../../utils/handleTracking"

const RoundedButton = (props) => {
  const { uri, to = "/find-a-retailer", icon = <NavIcon />, title = "Where to buy", color } = props

  // Function to trigger GA4 event via Google Tag Manager's dataLayer
  const handleClick = () => {
    // Continue existing tracking logic
    handleTracking("Lead", uri)
  }

  return (
    <StyledButton
      to={to}
      buttoncolor={color}
      onClick={handleClick} // Attach new GA4 tracking
    >
      {title} {icon}
    </StyledButton>
  )
}

export default RoundedButton

const StyledButton = styled(Button)`
  && {
    padding: 5px 24px;
    border-radius: 30px;
    background: ${(props) => (props.buttoncolor === "green" ? theme.colors.secondary : theme.colors.primary)};
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.02em;

    &:hover:before {
      background: ${(props) => (props.buttoncolor === "green" ? "#03a03c" : "#232323")};
    }

    svg {
      margin-left: 10px;
    }
  }
`
